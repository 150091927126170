import { useCallback } from 'react';

import { useRouter } from 'next/router';

import QuantityPicker from 'components/parts/QuantityPicker';
import { Icon, ECImage, ECLink } from 'components/ui';
import { useCart } from 'context/ShopifyCartProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import formatPrice from 'utils/formatPrice';

import Sticker from '../Sticker';

interface Props {
  cartItem: ReturnType<typeof useCart>['cart']['lines']['edges'][number]['node'];
  isGift?: boolean;
  usedInDrawer?: boolean; // Hover and background states are different in cart drawer compared to cart
}

const CartProduct: React.FC<Props> = ({ cartItem, isGift, usedInDrawer }) => {
  const { t } = useTranslations('cart');
  const { cartStates, cartActions, cartPreferences } = useCart();
  const { locale } = useRouter();

  const { isInStock } = cartItem.ecProductVariant.actuals;

  const onQuantityChanged = useCallback(
    (quantity: number) => {
      cartActions.updateCartItem(
        cartItem.ecProductVariant.universalKey,
        quantity,
        cartItem.ecLineItemType,
      );
    },
    [cartItem, cartActions],
  );

  const onDelete = () => {
    cartActions.removeFromCart(cartItem.ecProductVariant.universalKey, cartItem.ecLineItemType);
  };

  const deleteButtonMarkup = (
    <button
      type="button"
      onClick={onDelete}
      className={`inline-flex justify-center items-center p-1 rounded-lg text-gray-500 transition hover:text-white hover:bg-cyan-400 ${
        usedInDrawer ? 'bg-gray-50' : 'bg-white '
      }`}
    >
      <Icon name="trash" className="w-4 h-4" />
    </button>
  );

  const productHref = {
    href: `/products/${cartItem.ecProductVariant.product.content.handle}/${cartItem.ecProductVariant.content.slug}/`,
  };

  // Subscription doesn't take subtotal into account (old school calculation)
  const formattedSubtotal = formatPrice(
    cartItem.cost.subtotalAmount.amount,
    cartItem.cost.subtotalAmount.currencyCode,
    locale,
  );

  // Subscription doesn't take automatic discounts into account
  const formattedTotal = cartPreferences?.isSubscription
    ? null
    : formatPrice(cartItem.cost.totalAmount.amount, cartItem.cost.totalAmount.currencyCode, locale);

  const cartItemHasDiscount =
    parseFloat(cartItem.cost.subtotalAmount.amount) >
      parseFloat(cartItem.cost.totalAmount.amount) && !cartPreferences?.isSubscription;

  const cartItemThumb = (
    <ECImage
      srcOptions={{ w: 192, q: 90 }} // width= 96x2
      img={
        cartItem.ecProductVariant.content.featuredImage ??
        cartItem.ecProductVariant.product.content.featuredImage
      }
      className={`block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
       object-contain rounded-lg ${usedInDrawer ? 'w-20 h-20' : 'w-20 h-20 sm:w-24 sm:h-24'}`}
    />
  );

  const cartItemTitle = <span>{cartItem.ecProductVariant.product.content.title}</span>;

  return (
    <div className={`xs:flex font-rooney ${usedInDrawer ? 'flex-gap-3' : 'flex-gap-4'}`}>
      <div className="flex justify-between">
        <div
          className={`relative bg-cyan-400 flex justify-center items-center 
          shrink-0 rounded-lg ${usedInDrawer ? 'w-24 h-24' : 'w-24 h-24 sm:w-28 sm:h-28'}`}
        >
          <ECLink href={productHref.href}>{cartItemThumb}</ECLink>
        </div>
        <div className="xs:hidden">{deleteButtonMarkup}</div>
      </div>
      <div className="xs:flex grow justify-between">
        <div className="h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between mt-2 xs:mt-0">
              <div>
                <div className="flex flex-col items-start">
                  {cartItem.ecProductVariant.product.content.noLongerAvailable && (
                    <span className="uppercase text-sm text-white bg-red-400 mr-2 p-1 rounded">
                      {t('noLongerAvailableLabel')}
                    </span>
                  )}
                  {!isInStock && !cartItem.ecProductVariant.product.content.noLongerAvailable && (
                    <Sticker
                      innerClassName="text-sm font-bold uppercase flex justify-center items-center mb-[1px]"
                      sticker={{
                        displayText: `${
                          // ! Translations are different for cart and cart drawer: one comes from Contentful, other one is defined in the layout translations
                          usedInDrawer ? t('cartDrawer:outOfStock') : t('outOfStockLabel')
                        }`,
                        backgroundHexColor: '#f86767',
                        fontHexColor: '#FFFFFF',
                        name: 'outOfStockLabel',
                        bannerActiveForCountries: [],
                        link: productHref.href,
                      }}
                    />
                  )}
                  <ECLink
                    href={productHref.href}
                    className={`text-base font-bold leading-tight ${
                      usedInDrawer ? 'text-gray-500' : 'text-gray-400'
                    }`}
                  >
                    {cartItemTitle}
                  </ECLink>
                </div>
                <div className={`font-bold my-1 ${usedInDrawer ? 'text-xs' : 'text-sm'}`}>
                  <span className="text-gray-300">
                    {cartItem.ecProductVariant.content.title}{' '}
                    {!isGift && (
                      <span>
                        -{' '}
                        {formatPrice(
                          cartItem.cost.amountPerQuantity.amount,
                          cartItem.cost.amountPerQuantity.currencyCode,
                          locale,
                        )}
                      </span>
                    )}
                    {/* compareAtPrice */}
                    {!isGift && cartItem.cost.compareAtAmountPerQuantity && (
                      <span className="line-through ml-1 text-gray-125">
                        {formatPrice(
                          cartItem.cost.compareAtAmountPerQuantity.amount,
                          cartItem.cost.compareAtAmountPerQuantity.currencyCode,
                          locale,
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {!isGift && (
                <div className="mb-4 xs:hidden ml-4 font-bold text-gray-500">{formattedTotal}</div>
              )}
            </div>
          </div>
          {!isGift && (
            <div className="w-full xs:w-32 md:w-36 mt-1">
              <QuantityPicker
                usedInDrawer
                initialQuantity={cartItem.quantity}
                onQuantityChanged={onQuantityChanged}
                disabled={cartStates.isCartLoading || cartStates.isCartUpdating}
              />
            </div>
          )}
        </div>
        {!isGift && (
          <div className="h-full justify-between items-end hidden xs:flex xs:flex-col">
            {deleteButtonMarkup}
            <div className="text-right">
              {
                /**
                 * Discounted price (only Shopify checkout, not subscription checkout)
                 */
                cartItemHasDiscount && (
                  <span className="text-gray-400 font-rooney font-bold text-base block md:inline-block md:mr-2">
                    {formattedTotal}
                  </span>
                )
              }
              {
                /**
                 * Price before discount
                 */
                <span
                  className={`inline-block mb-[10px] font-rooney font-bold ${
                    usedInDrawer ? 'text-gray-500' : 'text-gray-400'
                  } ${cartItemHasDiscount ? 'line-through text-sm !text-gray-125' : 'text-base'}`}
                >
                  {formattedSubtotal}
                </span>
              }
            </div>
          </div>
        )}
        {isGift && (
          <span className="bg-pink-400 rounded-full font-rodetta uppercase text-white self-start text-sm px-2 py-1">
            {t('free')}
          </span>
        )}
      </div>
    </div>
  );
};

export default CartProduct;
