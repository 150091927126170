import { getPagePath } from 'utils/page-paths';

export interface NavItemProps {
  url: string;
  label: string;
  desktop?: boolean;
  mobile?: boolean;
  external?: boolean;
  children?: NavItemProps[];
  specificForCountries?: string[];
  trackingId?: string;
  stickerLabel?: string;
}

const getNavItems = (t: (key: string) => string, locale: string): NavItemProps[] => [
  {
    url: getPagePath({ path: '/collections/dogs', locale }),
    label: t('dogs'),
    desktop: true,
    mobile: true,
    trackingId: 'dogs',
    children: [
      {
        url: getPagePath({ path: '/collections/dogs', locale }),
        label: t('allProducts'),
        trackingId: 'dogs_all',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?type-group=dry-food`,
        label: t('dryFood'),
        trackingId: 'dogs_dry_food',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?type-group=wet-food`,
        label: t('wetFood'),
        trackingId: 'dogs_wet_food',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?type-group=treats`,
        label: t('treats'),
        trackingId: 'dogs_treats',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?type-group=dental`,
        label: t('dental'),
        trackingId: 'dogs_dental',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?type-group=bundles`,
        label: t('bundle'),
        stickerLabel: t('stickerSave5'),
        trackingId: 'dogs_bundle',
      },
      {
        url: `${getPagePath({ path: '/collections/dogs', locale })}?age=junior`,
        label: t('forPuppies'),
        trackingId: 'dogs_puppy',
      },
    ],
  },
  {
    url: getPagePath({ path: '/collections/cats', locale }),
    label: t('cats'),
    desktop: true,
    mobile: true,
    trackingId: 'cats',
    children: [
      {
        url: getPagePath({ path: '/collections/cats', locale }),
        label: t('allProducts'),
        trackingId: 'cats_all',
      },
      {
        url: `${getPagePath({ path: '/collections/cats', locale })}?type-group=dry-food`,
        label: t('dryFood'),
        trackingId: 'cats_dry_food',
      },
      {
        url: `${getPagePath({ path: '/collections/cats', locale })}?type-group=wet-food`,
        label: t('wetFood'),
        trackingId: 'cats_wet_food',
      },
      {
        url: `${getPagePath({ path: '/collections/cats', locale })}?age=junior`,
        label: t('forKittens'),
        trackingId: 'cats_kitten',
      },
    ],
  },

  {
    url: '/',
    label: t('learn'),
    desktop: true,
    mobile: true,
    children: [
      {
        url: '/our-food/',
        label: t('ourFood'),
      },
      {
        url: '/our-story/',
        label: t('ourStory'),
      },
      {
        url: '/our-planet/',
        label: t('ourPlanet'),
      },
      {
        url: '/blog/',
        label: 'Blog',
      },
      {
        url: 'https://cdn.edgardcooper.com/pdf/edgard_cooper_impact_report_2023.pdf',
        label: t('impactReport'),
        trackingId: 'our_impact_report',
      },
    ],
  },
  {
    url: '/store-locator/',
    label: t('storeFinder'),
    desktop: true,
    mobile: false,
  },
];

export default getNavItems;
